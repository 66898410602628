import { Component, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent {

}
