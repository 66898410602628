import { CommonModule, NgOptimizedImage, registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import localePL from "@angular/common/locales/pl";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, provideClientHydration } from "@angular/platform-browser";
import { BrowserAnimationsModule, NoopAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";

import { AutoCompleteModule } from "primeng/autocomplete";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { BadgeModule } from "primeng/badge";
import { ButtonModule } from "primeng/button";
import { BlockUIModule } from "primeng/blockui";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { CheckboxModule } from "primeng/checkbox";
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { EditorModule } from "primeng/editor";
import { FileUploadModule } from "primeng/fileupload";
import { FloatLabelModule } from 'primeng/floatlabel';
import { ImageModule } from "primeng/image";
import { InputGroupModule } from "primeng/inputgroup";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from "primeng/menu";
import { MultiSelectModule } from "primeng/multiselect";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { RippleModule } from "primeng/ripple";
import { SelectButtonModule } from "primeng/selectbutton";
import { SliderModule } from "primeng/slider";
import { StyleClassModule } from "primeng/styleclass";
import { TabViewModule } from "primeng/tabview";
import { TableModule } from "primeng/table";
import { TagModule } from "primeng/tag";
import { TimelineModule } from "primeng/timeline";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from "primeng/tooltip";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./shared/components/footer/footer.component";
import { ToolsComponent } from "./panel/tools/tools.component";
import { PanelHomeComponent } from "./panel/panel-home/panel-home.component";
import { CreatorHomeComponent } from "./creator/creator-home/creator-home.component";
import { StepsRouterComponent } from "./shared/components/steps-router/steps-router.component";
import { BookingComponent } from "./creator/booking/booking.component";
import { ArrowNavigatorComponent } from "./shared/components/arrow-navigator/arrow-navigator.component";
import { OrderFormComponent } from "./creator/order-form/order-form.component";
import { OrderSummaryComponent } from "./creator/order-summary/order-summary.component";
import { SanitizeHtmlPipe } from "./shared/pipes/sanitize-html.pipe";
import { PhoneCtrlComponent } from './shared/components/controls/phone-ctrl/phone-ctrl.component';
import { CreatorLayoutComponent } from './creator/creator-layout/creator-layout.component';
import { PanelLayoutComponent } from './panel/panel-layout/panel-layout.component';
import { ReservationsComponent } from './panel/reservations/reservations.component';
import { ReservationDetailsComponent } from './panel/reservations/reservation-details/reservation-details.component';
import { LocationsComponent } from './panel/locations/locations.component';
import { LocationDetailsComponent } from './panel/locations/location-details/location-details.component';
import { StoragesComponent } from './panel/storages/storages.component';
import { StorageDetailsComponent } from './panel/storages/storage-details/storage-details.component';
import { StorageContainersComponent } from './panel/storageContainers/storage-containers.component';
import { StorageContainerDetailsComponent } from './panel/storageContainers/storage-container-details/storage-container-details.component';
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ChipsModule } from "primeng/chips";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { FileSizePipe } from './shared/pipes/file-size.pipe';
import { LoginComponent } from './shared/login/login.component';
import { PasswordModule } from "primeng/password";
import { ResetPasswordComponent } from './shared/reset-password/reset-password.component';

registerLocaleData(localePL, "pl-PL");

@NgModule({
  declarations: [
    // Apps views
    AppComponent,
    ToolsComponent,
    PanelHomeComponent,
    CreatorHomeComponent,
    BookingComponent,
    ArrowNavigatorComponent,
    OrderFormComponent,
    OrderSummaryComponent,
    SanitizeHtmlPipe,
    PhoneCtrlComponent,
    CreatorLayoutComponent,
    PanelLayoutComponent,
    ReservationsComponent,
    ReservationDetailsComponent,
    LocationsComponent,
    LocationDetailsComponent,
    StoragesComponent,
    StorageDetailsComponent,
    StorageContainersComponent,
    StorageContainerDetailsComponent,
    FileSizePipe,
    LoginComponent,
    ResetPasswordComponent,
  ],
  providers: [
    provideClientHydration(),
    {
      provide: LOCALE_ID,
      useValue: "pl-PL",
    }
  ],
  bootstrap: [AppComponent],
  imports: [
    // Angular modules
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgOptimizedImage,
    NoopAnimationsModule,
    RouterModule,
    // Primeng modules
    AutoCompleteModule,
    AvatarModule,
    AvatarGroupModule,
    BadgeModule,
    ButtonModule,
    BlockUIModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    ChipsModule,
    ConfirmDialogModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    EditorModule,
    FileUploadModule,
    FloatLabelModule,
    ImageModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputMaskModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    ListboxModule,
    MenuModule,
    MultiSelectModule,
    OverlayPanelModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    RippleModule,
    StyleClassModule,
    TableModule,
    TabViewModule,
    TagModule,
    TimelineModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    SelectButtonModule,
    SliderModule,
    // Apps components
    FooterComponent,
    StepsRouterComponent,
    ReactiveFormsModule,
    PasswordModule,
  ],
})
export class AppModule {
}
