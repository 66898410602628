import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Table } from "primeng/table";
import { Customer, Representative } from "../reservations/reservations.component";
import { Location } from "../../shared/data/models/location";
import { FetchDataService } from "../../shared/data/services/fetch-data.service";

@Component({
  selector: 'locations',
  templateUrl: './locations.component.html',
  styleUrl: './locations.component.scss'
})
export class LocationsComponent implements OnInit {
  locations!: Location[];

  selectedLocation!: Location;

  representatives!: Representative[];

  statuses!: any[];

  loading: boolean = true;

  activityValues: number[] = [0, 100];

  constructor(private fetchData: FetchDataService, private router: Router) {}

  ngOnInit() {
    this.fetchData.getLocations().subscribe({
      next: (response: any) => {
        this.locations = response.data;
      },
    });

    this.loading = false;

    this.representatives = [
      { name: 'Amy Elsner', image: 'amyelsner.png' },
      { name: 'Anna Fali', image: 'annafali.png' },
      { name: 'Asiya Javayant', image: 'asiyajavayant.png' },
      { name: 'Bernardo Dominic', image: 'bernardodominic.png' },
      { name: 'Elwin Sharvill', image: 'elwinsharvill.png' },
      { name: 'Ioni Bowcher', image: 'ionibowcher.png' },
      { name: 'Ivan Magalhaes', image: 'ivanmagalhaes.png' },
      { name: 'Onyama Limba', image: 'onyamalimba.png' },
      { name: 'Stephen Shaw', image: 'stephenshaw.png' },
      { name: 'Xuxue Feng', image: 'xuxuefeng.png' }
    ];

    this.statuses = [
      { label: 'Unqualified', value: 'unqualified' },
      { label: 'Qualified', value: 'qualified' },
      { label: 'New', value: 'new' },
      { label: 'Negotiation', value: 'negotiation' },
      { label: 'Renewal', value: 'renewal' },
      { label: 'Proposal', value: 'proposal' }
    ];

    console.log("> Locations list");
    console.log(this.selectedLocation);
  }

  clear(table: Table) {
    table.clear();
  }

  searchInput(dt: Table, event: any) {
    // console.log(event);
    dt.filterGlobal(event.target.value, 'contains');
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'unqualified':
        return 'danger';

      case 'qualified':
        return 'success';

      case 'new':
        return 'info';

      case 'negotiation':
        return 'warning';

      case 'renewal':
        return undefined;
      default:
        return undefined;
    }
  }

  getStatusSeverity(status: string){
    switch (status) {
      case 'PENDING':
        return 'warning';
      case 'DELIVERED':
        return 'success';
      case 'CANCELLED':
      default:
        return 'danger';
    }
  }

  openRow(event: any, orderId: string) {
    console.log("Open row");
    console.log(event);
    if (
        typeof event.target.className == 'string' &&
        !event.target.className.includes('p-checkbox-box') &&
        !event.target.className.includes('p-button-icon')
      ) {
      console.log(orderId);
      this.router.navigate(
        ['/panel/locations/details'],
        {
          queryParams: {
            "id": orderId
          },
        }
      );
    }
  }
}
