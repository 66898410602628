<div class="text-center px-4 py-8 md:px-6 lg:px-8">
  <steps-router></steps-router>

  <form [formGroup]="orderForm" (ngSubmit)="onSubmit();">
    <div class="surface-section">
      <div class="grid grid-nogutter">
        <div class="col-12 lg:col-6 h-full px-4 py-8 md:px-6 lg:px-8">
          <div class="grid formgrid">
            <div class="col-12 field mb-3">
              <p-divider styleClass="w-full border-gray-200"></p-divider>
            </div>
            <div class="col-12 field mb-3">
              <span class="text-900 text-2xl block font-medium mb-5">Dane rozliczeniowe</span>
            </div>
            <div class="col-12 field p-fluid mb-4">
              <p-floatLabel>
                <input id="email" type="email" formControlName="email" pInputText class="p-inputtext w-full"
                       [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.email.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.email.errors }">
                <label for="email"> E-mail <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
              </p-floatLabel>
              <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.email.errors?.required">Podaj adres e-mail.</small>
              <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.email.errors?.email">Podany adres e-mail jest niepoprawny.</small>
            </div>
            <div class="col-12 field p-fluid mb-4">
              <!--<phone-ctrl [phoneNumberControl]="phoneNumber" [countryCodeControl]="countryCode"></phone-ctrl>-->
              <p-floatLabel>
                <input id="phone" type="text" formControlName="phone" pInputText class="p-inputtext w-full"
                       [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.phone.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.phone.errors }"
                >
                <label for="phone"> Telefon <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
              </p-floatLabel>
              <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.phone.errors?.required">Podaj numer telefonu.</small>
              <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.phone.errors?.pattern">Podany numer telefonu jest niepoprawny.</small>
            </div>
            <div class="col-12 field p-fluid mb-4 mt-3">
              <div class="grid">
                <div class="col-6 align-items-center">
                  <p-radioButton name="subjectType" formControlName="subjectType" value="person" inputId="person"
                                 [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.subjectType.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.subjectType.errors }"
                  ></p-radioButton>
                  <label for="person" class="ml-2">Osoba fizyczna</label>
                </div>
                <div class="col-6 align-items-center">
                  <p-radioButton name="subjectType" formControlName="subjectType" value="company" inputId="company"
                                 [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.subjectType.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.subjectType.errors }"
                  ></p-radioButton>
                  <label for="company" class="ml-2">Firma</label>
                </div>
                <div class="col-12 align-items-center" *ngIf="this.formSentinel.submitted && this.orderForm.controls.subjectType.errors?.required">
                  <small class="p-error">Wybierz podmiot prawny.</small>
                </div>
              </div>
            </div>
            @if (orderForm.controls.subjectType.getRawValue() != '') {
              @if (orderForm.controls.subjectType.getRawValue() == 'person') {
                <div class="col-12 field p-fluid mb-4">
                  <p-floatLabel>
                    <input id="surnames" formControlName="surNames" type="text" pInputText class="p-inputtext w-full"
                           [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.surNames.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.surNames.errors }"
                    >
                    <label for="surnames"> Imię / Imiona <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                  </p-floatLabel>
                  <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.surNames.errors?.required">Podaj imię / imiona .</small>
                </div>
                <div class="col-12 field p-fluid mb-4">
                  <p-floatLabel>
                    <input id="lastname" formControlName="lastName" type="text" pInputText class="p-inputtext w-full" aria-describedby="lastName-message"
                           [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.lastName.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.lastName.errors }"
                    >
                    <label for="lastName"> Nazwisko <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                  </p-floatLabel>
                  <small id="lastName-message" class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.lastName.errors?.required">Podaj nazwisko.</small>
                </div>
                <div class="col-12 field p-fluid mb-4">
                  <div class="flex justify-content-center flex-wrap gap-2 sm:gap-5">
                    <div class="flex align-items-center">
                      <span>Posiadasz PESEL: </span>
                    </div>
                    <div class="flex align-items-center">
                      <p-radioButton name="hasPESEL" formControlName="hasPESEL" [value]="1" inputId="plCitizen"
                                     [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.hasPESEL.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.hasPESEL.errors }"
                      ></p-radioButton>
                      <label for="plCitizen" class="ml-2">tak</label>
                    </div>
                    <div class="flex align-items-center">
                      <p-radioButton name="hasPESEL" formControlName="hasPESEL" [value]="0" inputId="nonplCitizen"
                                     [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.hasPESEL.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.hasPESEL.errors }"
                      ></p-radioButton>
                      <label for="hasPESEL" class="ml-2">nie</label>
                    </div>
                  </div>
                  <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.hasPESEL.errors?.required">Podaj, czy posiadasz numer PESEL.</small>
                </div>
                @if (this.orderForm.controls.hasPESEL.value != null) {
                  @if (orderForm.get("hasPESEL")?.value == 1) {
                    <div class="col-12 field p-fluid mb-4">
                      <p-floatLabel>
                        <input id="pesel" formControlName="PESEL" pInputText type="text" class="p-inputtext w-full"
                                [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.PESEL.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.PESEL.errors }"
                        >
                        <label for="pesel"> PESEL <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                      </p-floatLabel>
                      <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.PESEL.errors?.required">Podaj numer PESEL.</small>
                      <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.PESEL.errors?.required === undefined && this.orderForm.controls.PESEL.errors?.invalidPesel">Numer PESEL jest nieprawidłowy.</small>
                    </div>
                  } @else {
                    <div class="col-12 field p-fluid mb-4">
                      <p-floatLabel>
                        <input id="passportNumber" formControlName="passportNumber" pInputText type="text" class="p-inputtext w-full"
                                [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.passportNumber.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.passportNumber.errors }"
                        >
                        <label for="passportNumber"> Numer paszportu <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                      </p-floatLabel>
                      <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.passportNumber.errors?.required">Podaj numer paszportu.</small>
                    </div>
                  }
                } @else {
                  <!--<div class="col-12 field mb-4">
                    <div class="border-2 border-dashed surface-border flex-auto overflow-y-auto p-3 mx-2 my-3">
                      <p>
                        <i class="fa-solid fa-arrow-up"></i> Czy posiadasz numer PESEL?
                      </p>
                    </div>
                  </div>-->
                }
              } @else if (this.orderForm.controls.subjectType.value == 'company') {
                <!--<div class="col-12 field p-fluid mb-4">
                  <span class="p-float-label">
                    <p-dropdown [options]="companyTypes" formControlName="companyType" optionLabel="name" [showClear]="true" inputId="companyType"
                                styleClass="w-full text-center" [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.get('companyType')?.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.get('companyType')?.errors }">
                    </p-dropdown>
                    <label for="companyType">Rodzaj przedsiębiorstwa <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                  </span>
                  <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.get('companyType')?.errors?.required" >Wybierz rodzaj przedsiębiorstwa.</small>
                </div>-->
                <div class="col-12 field p-fluid mb-4">
                  <p-floatLabel>
                    <p-inputMask id="nip" type="text" formControlName="nipNumber" styleClass="w-full" characterPattern="[A-Z]" slotChar="XX ### ### ## ##" mask="aa 999 999 99 99"
                                 [autoClear]="true" [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.nipNumber.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.nipNumber.errors }">
                    </p-inputMask>
                    <label for="nip">NIP <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                  </p-floatLabel>
                  <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.nipNumber.errors?.required">Podaj numer NIP z kodem kraju.</small>
                </div>
                <!--<div class="col-12 field p-fluid mb-4">
                  <p-floatLabel>
                    <input id="regon" formControlName="regonNumber" pInputText type="text" class="p-inputtext w-full"
                           [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.get('regonNumber')?.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.get('regonNumber')?.errors }"
                    >
                    <label for="regon">REGON <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                  </p-floatLabel>
                  <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.get('regonNumber')?.errors?.required">Podaj numer REGON.</small>
                </div>
                @if (orderForm.controls.companyType.value && this.orderForm.controls.companyType.value.requireKRS) {
                  <div class="col-12 field p-fluid mb-4">
                    <p-floatLabel>
                      <input id="krs" formControlName="krsNumber" pInputText type="text" class="p-inputtext w-full"
                             [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.get('krsNumber')?.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.get('krsNumber')?.errors }"
                      >
                      <label for="krs">KRS <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                    </p-floatLabel>
                    <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.get('krsNumber')?.errors?.required">Podaj numer KRS.</small>
                  </div>
                }-->
                <div class="col-12 field mb-4">
                  <p-floatLabel>
                    <input id="cn" formControlName="companyName" pInputText type="text" class="p-inputtext w-full"
                           [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.companyName.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.companyName.errors }">
                    <label for="cn">Nazwa firmy <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                  </p-floatLabel>
                  <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.companyName.errors?.required">Podaj nazwę firmy.</small>
                </div>
              }
              @if (!["", null].includes(this.orderForm.controls.subjectType.getRawValue()) || !["", null].includes(this.orderForm.controls.hasPESEL.value)) {
                <div class="col-12 field mb-4">
                  <p-floatLabel>
                    <input id="address" formControlName="addressName" pInputText type="text" class="p-inputtext w-full"
                           [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.addressName.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.addressName.errors }" >
                    <label for="address">Adres (ulica / aleja) <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                  </p-floatLabel>
                  <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.addressName.errors?.required">Podaj adres.</small>
                </div>
                <div class="col-12 lg:col-6 field mb-4">
                  <p-floatLabel>
                    <input id="addressNumber" formControlName="addressNumber" pInputText type="text" class="p-inputtext w-full"
                           [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.addressNumber.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.addressNumber.errors }"
                    >
                    <label for="addressNumber">Numer ulicy <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                  </p-floatLabel>
                  <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.addressNumber.errors?.required">Podaj numer ulicy.</small>
                </div>
                <div class="col-12 lg:col-6 field mb-4">
                  <p-floatLabel>
                    <input id="address-apartment" formControlName="addressApartment" pInputText type="text" class="p-inputtext w-full">
                    <label for="address-apartment">Numer lokalu</label>
                  </p-floatLabel>
                </div>
                <div class="col-12 field mb-4">
                  <p-floatLabel>
                    <input id="pc" formControlName="postalCode" pInputText type="text" class="p-inputtext w-full"
                           [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.postalCode.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.postalCode.errors }"
                    >
                    <label for="pc">Kod pocztowy <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                  </p-floatLabel>
                  <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.postalCode.errors?.required">Podaj kod pocztowy.</small>
                </div>
                <div class="col-12 field mb-4">
                  <p-floatLabel>
                    <input id="town" formControlName="town" pInputText type="text" class="p-inputtext w-full"
                           [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.town.errors, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.town.errors }"
                    >
                    <label for="town">Miejscowość <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                  </p-floatLabel>
                  <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.controls.town.errors?.required">Podaj miejscowość.</small>
                </div>
              }
            } @else {
              <div class="col-12 field mb-4">
                <div class="border-2 border-dashed surface-border flex-auto overflow-y-auto p-3 mx-2 my-3">
                  <p>
                    <i class="fa-solid fa-arrow-up"></i> Wybierz podmiot prawny.
                  </p>
                </div>
              </div>
            }
            <div class="col-12 field mb-3">
              <p-divider styleClass="w-full border-gray-200"></p-divider>
              <span class="text-900 text-2xl block font-medium mb-5">Zgody i umowa przedwstępna</span>
            </div>
            <div class="col-12 mb-3 flex flex-row align-items-center gap-1 text-left">
              <p-checkbox [value]="true" [binary]="true" class="text-900" inputId="emailNewsletter" formControlName="newsletterCheck"></p-checkbox>
              <label for="newsletterCheck">
                Wyrażam zgodę na otrzymywanie wiadomości e-mail z nowościami, promocjami i ofertami.
              </label>
            </div>
            <div class="col-12 mb-3 flex align-items-center gap-1 text-left">
              <p-checkbox [value]="true" [binary]="true" inputId="atos" formControlName="atos"
                          [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.atos.errors?.required, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.atos.errors?.required }"
              ></p-checkbox>
              <label for="atos" [ngClass]="{'p-error': this.formSentinel.submitted && this.orderForm.controls.atos.errors?.required }">
                <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> Akceptuję <a href="#" target="_blank">regulamin serwisu</a>
              </label>
            </div>
            <div class="col-12 mb-3 flex flex-row align-items-center gap-1 text-left">
              <p-checkbox [value]="true" [binary]="true" inputId="paf" formControlName="paf"
                          [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.paf.errors?.required, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.paf.errors?.required }"
              ></p-checkbox>
              <label for="paf" [ngClass]="{'p-error': this.formSentinel.submitted && this.orderForm.controls.paf.errors?.required}">
                <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> Oświadczam, że zapoznałem/-am się z umową przedwstępną.
              </label>
            </div>
            <div class="col-12 mb-3 flex flex-row align-items-center gap-1 text-left">
              <p-checkbox [value]="true" [binary]="true" inputId="pas" formControlName="pas"
                          [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.pas.errors?.required, 'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.pas.errors?.required }"
              ></p-checkbox>
              <label for="pas" [ngClass]="{'p-error': this.formSentinel.submitted && this.orderForm.controls.pas.errors?.required}">
                <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> Oświadczam, że załączam umowę przedwstępną wraz z podpisem osoby upoważnionej / podmiotu upoważnionego.
              </label>
            </div>
            @if (orderForm.controls.subjectType.getRawValue() == 'person') {
              <div class="col-12 mb-3 flex align-items-center gap-1 text-left">
                <p-checkbox [value]="true" [binary]="true" inputId="krdCheck" formControlName="krdCheck"
                            [ngClass]="{
                              'ng-invalid': this.formSentinel.submitted && this.orderForm.controls.krdCheck.errors?.required,
                              'ng-dirty': this.formSentinel.submitted && this.orderForm.controls.krdCheck.errors?.required
                            }"
                ></p-checkbox>
                <label for="krdCheck" [ngClass]="{'p-error': this.formSentinel.submitted && this.orderForm.controls.krdCheck.errors?.required}">
                  <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> Wyrażam zgodę, na pobranie mojej historii w Krajowym Rejestrze Długów (KRD).
                </label>
              </div>
            }
            <div class="col-12 field mb-3">
              <p-messages severity="info">
                <ng-template pTemplate>
                  <i class="fa-solid fa-circle-exclamation"></i>
                  <div class="ml-2 w-full">
                  <span>
                    Poniżej pobierz umowę przedwstępną. <br />
                    Zapoznaj się z jej treścią oraz podpisz odręcznie lub elektronicznie. <br />
                    Wgraj skan/zdjęcia podpisanej umowy w następnym polu. <br />
                  </span>
                  </div>
                </ng-template>
              </p-messages>
            </div>
            <div class="col-12 field mb-3 flex flex-column justify-content-center">
              <p-button type="submit" label="Pobierz umową przedstępną" icon="fa-solid fa-file-arrow-down" iconPos="right"
                        (click)="this.clickDownloadContract();">
              </p-button>
              <small class="p-error" *ngIf="this.formSentinel.submitted && !this.formSentinel.downloadContract">Pobierz umowę przedwstępną.</small>
              <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.invalid && !this.formSentinel.downloadContract">Formularz powyżej zawiera błędy.</small>
              <small class="p-error" *ngIf="this.formSentinel.submitted && this.orderForm.invalid && this.formSentinel.downloadContract">Zaakceptuj wymagane zgody poniżej.</small>
            </div>
            <div class="col-12 field mb-3">
              <p-fileUpload #signedContractField name="signedContractField" [multiple]="true" [maxFileSize]="10000000" [customUpload]="true"
                            accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text"
                            (uploadHandler)="onUploadSignedContract($event);" (onSelect)="onSelectFile($event);" (onRemove)="onRemoveFile($event);" [showUploadButton]="false"
              >
                <ng-template pTemplate="toolbar">
                  <div class="pt-4 pb-0">Wgrano plików: {{this.signedContractField.files.length}}</div>
                </ng-template>
                <ng-template pTemplate="content">
                  <span [ngClass]="{'p-error': this.formSentinel.submitted && !this.formSentinel.signedContract}" *ngIf="this.signedContractField.files.length == 0">
                    <span *ngIf="this.formSentinel.submitted && !this.formSentinel.signedContract" class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> Wgraj plik/pliki zawierającą podpisaną umowę.
                  </span>
                </ng-template>
              </p-fileUpload>
            </div>
            <div class="col-12 field mb-3 lg:hidden">
              <p-divider styleClass="w-full border-gray-200"></p-divider>
            </div>
            <!-- <div class="col-12 field mb-4">
              <p-checkbox name="checkbox-1" [(ngModel)]="checked3" [binary]="true" label="Save for next purchase"
                class="text-900" inputId="id"></p-checkbox>
            </div> -->
            <!-- <div
              class="col-12 flex flex-column lg:flex-row justify-content-center align-items-center lg:justify-content-end mt-6">
              <button pButton pRipple
                class="p-button-text mt-3 lg:mt-0 w-full lg:w-auto lg:px-6 flex-order-2 lg:flex-order-1 lg:mr-4"
                label="Return to Cart"></button>
              <button pButton pRipple class="p-button-primary w-full lg:w-auto lg:px-6 flex-order-1 lg:flex-order-2"
                label="Continue to Shipping"></button>
            </div> -->
          </div>
        </div>
        <div class="col-12 lg:col-6 px-4 pb-8 md:px-6 lg:px-8 surface-50">
          <div class="md:sticky top-0">
            <div class="pt-8">
            </div>
            <div class="border-bottom-1 pb-3 surface-border">
              <span class="text-900 font-medium text-xl">Twoja rezerwacja</span>
            </div>
            @for (item of reservationDetails; track $index) {
              <div
                class="flex flex-column lg:flex-row flex-wrap lg:align-items-center py-2 mt-3 border-bottom-1 surface-border">
                <img class="w-8rem h-8rem flex-shrink-0 mb-3" alt="image" src="{{ item.locationStoragesGroup.thumbnailUrl }}" />
                <div class="flex-auto lg:ml-3">
                  <div class="flex align-items-center justify-content-between mb-3">
                    <span class="text-900 font-medium">{{ item.locationStoragesGroup.group.name }}</span>
                    <!-- <span class="text-900 font-bold">$123.00</span> -->
                  </div>
                  <div class="text-600 text-sm mb-3">
                    {{ item.locationStoragesGroup.nettoUnitPrice | currency:'PLN' }} netto / szt.
                  </div>
                  <div class="flex flex-auto justify-content-between align-items-center">
                    <p-inputNumber [disabled]="true" [showButtons]="false" buttonLayout="horizontal" spinnerMode="horizontal"
                                   inputStyleClass="w-3rem text-center py-2 px-1 border-transparent" class="border-1 surface-border border-round"
                                   [(ngModel)]="item.quantity" [ngModelOptions]="{standalone: true}"
                    >
                    </p-inputNumber>
                    <!-- <button pButton pRipple icon="pi pi-trash" class="p-button-text p-button-rounded"></button> -->
                  </div>
                </div>
              </div>
            }
            <div class="surface-card p-4 mt-5 shadow-2 border-round">
              <div class="flex justify-content-between align-items-center mb-3">
                <span class="text-900 font-medium">Długość rezerwacji: </span>
                <span class="text-900">{{ this.rentalPeriod }} msc</span>
              </div>
              <div class="flex justify-content-between align-items-center mb-3">
                <span class="text-900 font-medium">Wartość rezerwacji: </span>
                <span class="text-900">{{ this.cartValue | currency:'PLN' }} brutto</span>
              </div>
            </div>
            <!--<div class="surface-card p-4 mt-5 shadow-2 border-round">
              <div class="mb-2 flex align-items-center justify-content-between">
                <div class="flex align-items-center">
                  <i class="fa-regular fa-credit-card text-500 mr-2 text-xl"></i>
                  <span class="text-3xl font-medium text-900 mb-2">Płatność</span>
                </div>
              </div>
              &lt;!&ndash;<div class="font-medium text-500 mb-3 text-left">Wybierz dogodną formę płatności klikając odpowiedni kafelek.</div>&ndash;&gt;
              <div class="grid justify-content-between mt-auto">

                <div class="w-full">
                  &lt;!&ndash; <div class="text-900 text-xl mb-3 text-left font-medium">Choose your favorite payment</div> &ndash;&gt;
                  &lt;!&ndash;<div
                    class="surface-card border-2 p-5 mb-3 border-round flex flex-column md:flex-row align-items-start md:align-items-center md:justify-content-between cursor-pointer"
                    (click)="payment=0; this.feePayment = ((this.cartValue * 0.0119) + 0.25); this.formSentinel.paymentMethod = 'autopay';"
                    [ngClass]="{
                      'surface-border': (!this.formSentinel.submitted || this.formSentinel.paymentMethod != '') && payment !== 0,
                      'border-primary': payment === 0,
                      'border-red-600': this.formSentinel.submitted && this.formSentinel.paymentMethod == ''
                    }">
                    <div class="flex flex-column gap-1 align-items-center w-full">
                      <div class="justify-content-center">
                        <div class="w-full">
                          <img alt="test1" src="https://blocks.primeng.org/assets/images/blocks/payments/a1.jpg" class="mr-3 border-round"
                               style="width:50px" />
                        </div>
                        <div class="font-medium mb-2" [ngClass]="{'text-primary-600': payment === 0}">
                          AutoPay
                        </div>
                      </div>
                      <div class="flex flex-row justify-content-center w-full gap-8 mt-2">
                        <div class="flex flex-grow gap-2">
                        <span class="py-1 px-2 border-round surface-100" [ngClass]="{'text-primary-600': payment === 0}">
                          <i class="fa-solid fa-percent"></i>
                          0,25 zł + 1,19%
                        </span>
                        </div>
                        <div class="flex border-1 border-round align-items-center ml-1 xl:ml-4 mt-2 md:mt-0 p-1 px-2 font-medium"
                             [ngClass]="{'surface-100 text-600 surface-border': payment !== 0, 'bg-primary-reverse': payment === 0}">
                          <i class="fa-solid fa-coins mr-2"></i>
                          <span>{{ (this.cartValue * 0.0119) + 0.25 | currency:'PLN' }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="surface-card border-2 p-5 mb-3 border-round flex flex-column md:flex-row align-items-start md:align-items-center md:justify-content-between cursor-pointer"
                    (click)="payment=1; this.feePayment = (this.cartValue * 0.0229); this.formSentinel.paymentMethod = 'przelewy24';"
                    [ngClass]="{
                      'surface-border': (!this.formSentinel.submitted || this.formSentinel.paymentMethod != '') && payment !== 1,
                      'border-primary': payment === 1,
                      'border-red-600': this.formSentinel.submitted && this.formSentinel.paymentMethod == ''
                    }">
                    <div class="flex flex-column gap-1 align-items-center w-full">
                      <div class="justify-content-center">
                        <div class="w-full">
                          <img alt="test2" src="https://blocks.primeng.org/assets/images/blocks/payments/a2.jpg" class="mr-3 border-round"
                               style="width:50px" />
                        </div>
                        <div class="font-medium mb-2" [ngClass]="{'text-primary-600': payment === 1}">
                          Przelewy24
                        </div>
                      </div>
                      <div class="flex flex-row justify-content-center w-full gap-8 mt-2">
                        <div class="flex flex-grow gap-2">
                        <span class="py-1 px-2 border-round surface-100" [ngClass]="{'text-primary-600': payment === 1}">
                          <i class="fa-solid fa-percent"></i>
                          2,29%
                        </span>
                        </div>
                        <div class="flex border-1 border-round align-items-center ml-1 xl:ml-4 mt-2 md:mt-0 p-1 px-2 font-medium"
                             [ngClass]="{'surface-100 text-600 surface-border': payment !== 1, 'bg-primary-reverse': payment === 1}">
                          <i class="fa-solid fa-coins mr-2"></i>
                          <span>{{ (this.cartValue * 0.0229) | currency:'PLN' }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="surface-card border-2 p-5 mb-3 border-round flex flex-column md:flex-row align-items-start md:align-items-center md:justify-content-between cursor-pointer"
                    (click)="this.selectPayment('zen');"
                    [ngClass]="{
                      'surface-border': (!this.formSentinel.submitted || this.formSentinel.paymentMethod != '') && this.formSentinel.paymentMethod != 'zen',
                      'border-primary': this.formSentinel.paymentMethod == 'zen',
                      'border-red-600': this.formSentinel.submitted && this.formSentinel.paymentMethod == ''
                    }">
                    <div class="flex flex-column gap-1 align-items-center w-full">
                      <div class="justify-content-center">
                        <div class="w-full">
                          <img alt="test3" src="https://blocks.primeng.org/assets/images/blocks/payments/a3.jpg" class="mr-3 border-round"
                               style="width:50px" />
                        </div>
                        <div class="font-medium mb-2" [ngClass]="{'text-primary-600': this.formSentinel.paymentMethod == 'zen'}">
                          Zen
                        </div>
                      </div>
                      <div class="flex flex-row justify-content-center w-full gap-8 mt-2">
                        <div class="flex flex-grow gap-2">
                        <span class="py-1 px-2 border-round surface-100" [ngClass]="{'text-primary-600': this.formSentinel.paymentMethod == 'zen'}">
                          <i class="fa-solid fa-percent"></i>
                          {{ this.feePayment | currency: 'PLN' }}
                        </span>
                        </div>
                        <div class="flex border-1 border-round align-items-center ml-1 xl:ml-4 mt-2 md:mt-0 p-1 px-2 font-medium"
                             [ngClass]="{'surface-100 text-600 surface-border': this.formSentinel.paymentMethod != 'zen', 'bg-primary-reverse': this.formSentinel.paymentMethod == 'zen'}">
                          <i class="fa-solid fa-coins mr-2"></i>
                          <span>{{ this.feePayment | currency: 'PLN' }}</span>
                        </div>
                      </div>
                    </div>
                  </div>&ndash;&gt;
                  <div
                    class="surface-card border-2 p-5 mb-3 border-round flex flex-column md:flex-row align-items-start md:align-items-center md:justify-content-between cursor-pointer"
                    (click)="this.selectPayment('fakturownia');"
                    [ngClass]="{
                      'surface-border': (!this.formSentinel.submitted || this.formSentinel.paymentMethod != '') && this.formSentinel.paymentMethod != 'fakturownia',
                      'border-primary': this.formSentinel.paymentMethod == 'fakturownia',
                      'border-red-600': this.formSentinel.submitted && this.formSentinel.paymentMethod == ''
                    }">
                    <div class="flex flex-column gap-1 align-items-center w-full">
                      <div class="justify-content-center">
                        <div class="w-full affiliate-banner affiliate-banner&#45;&#45;vibrant-blue">
                          <img src="https://fs.siteor.com/radgost/files/svg-banners/f-sygnet.svg" class="affiliate-banner__logo" alt="logo"/>
                          <p class="affiliate-banner__text">
                            <span class="affiliate-banner__text&#45;&#45;wide">Fakturownia.pl</span><br/>
                            <span class="affiliate-banner__text&#45;&#45;small">prosty program do fakturowania online</span>
                          </p>
                          <br/>
                        </div>
                        <div class="font-medium mb-2" [ngClass]="{'text-primary-600': this.formSentinel.paymentMethod == 'fakturownia'}">
                          Płatność przez Fakturownia.pl
                        </div>
                      </div>
                      <div class="flex flex-row justify-content-center w-full gap-8 mt-2">
                        <div class="flex flex-grow gap-2">
                        <span class="py-1 px-2 border-round surface-100" [ngClass]="{'text-primary-600': this.formSentinel.paymentMethod == 'fakturownia'}">
                          <i class="fa-solid fa-percent"></i>
                          {{ this.paymentsCommissions.fakturownia | currency: 'PLN' }}
                        </span>
                        </div>
                        <div class="flex border-1 border-round align-items-center ml-1 xl:ml-4 mt-2 md:mt-0 p-1 px-2 font-medium"
                             [ngClass]="{'surface-100 text-600 surface-border': this.formSentinel.paymentMethod !== 'fakturownia', 'bg-primary-reverse': this.formSentinel.paymentMethod == 'fakturownia'}">
                          <i class="fa-solid fa-coins mr-2"></i>
                          <span>{{ this.paymentsCommissions.fakturownia | currency: 'PLN' }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              &lt;!&ndash;<small class="p-error" *ngIf="this.formSentinel.submitted && this.formSentinel.paymentMethod == ''">Wybierz formę płatności.</small>&ndash;&gt;
            </div>-->
            <p-divider class="w-full"></p-divider>
            <!--<div class="py-2 mt-3">
              @if (this.formSentinel.paymentMethod !== "") {
                <div class="flex justify-content-between align-items-center mb-3">
                  <span class="text-900 font-medium">Prowizja: </span>
                  <span class="text-900">{{ this.paymentsCommissions[this.formSentinel.paymentMethod] | currency:'PLN' }}</span>
                </div>
                <div class="flex justify-content-between align-items-center mb-3">
                  <span class="text-900 font-bold">Do zapłaty: </span>
                  <span class="text-primary font-medium text-xl">
                    {{ (this.cartValue + this.paymentsCommissions[this.formSentinel.paymentMethod]) | currency:'PLN' }} brutto
                  </span>
                </div>
              } @else {
                <div class="flex justify-content-between align-items-center my-1">
                  <i class="fa-solid fa-arrow-up block"
                     [ngClass]="{
                     'p-error': this.formSentinel.submitted && this.formSentinel.paymentMethod == '',
                     'text-lg': this.formSentinel.submitted && this.formSentinel.paymentMethod == '',
                     }"></i>
                  <p
                    [ngClass]="{
                    'p-error': this.formSentinel.submitted && this.formSentinel.paymentMethod == '',
                    'text-lg': this.formSentinel.submitted && this.formSentinel.paymentMethod == '',
                  }">Wybierz formę płatności</p>
                </div>
              }
            </div>-->
            <!-- <div class="py-2 mt-3 bg-yellow-100 flex align-items-center justify-content-center">
              <img src="https://blocks.primeng.org/assets/images/blocks/ecommerce/checkoutform/checkoutform-1-3.png"
                class="mr-2" alt="Country Flag"><span class="text-black-alpha-90 font-medium">No additional duties or
                taxes.</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Płatność -->

    <div class="grid flex py-3 sm:py-4">
      <div class="col-12 lg:col-12">
      <span class="p-buttonset">
        <p-button label="Wstecz" icon="pi pi-angle-left" iconPos="left" routerLink="/creator/booking"></p-button>
        <p-button label="Następny" icon="pi pi-angle-right" iconPos="right" type="submit" (onClick)="next($event);"></p-button>
      </span>
      </div>
    </div>
  </form>

</div>

<p-dialog [(visible)]="nextStep" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
          [style]="{width: '40vw'}" [closable]="false" [showHeader]="false">
  @if (!this.formSentinel.waitingForPayment) {
    <div class="flex flex-column align-items-center my-4">
    <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3"
          style="width:64px;height:64px">
      <i class="fa-solid fa-list-check text-5xl"></i>
    </span>
      <div class="font-medium text-2xl text-900">Zapisano prawidłowo.</div>
    </div>
    <p class="line-height-3 p-0 mx-0 my-1">
      Kliknij przycisk <span class="font-italic font-semibold">Opłać</span>, by zakończyć proces rezerwacji i przejść do bramki płatności.
    </p>
    <p class="line-height-3 p-0 mx-0 my-1">
      Zrealizuj <span class="font-bold">płatność w nowej zakładce przeglądarki</span>, która otworzy się automatycznie po kliknięciu.
    </p>
    <p class="line-height-3 p-0 mx-0 my-1">
      Jeśli chcesz zmodyfikować rezerwację, to naciśnij przycisk "Wróć".
    </p>
  } @else {
    <div class="flex flex-column align-items-center my-4">
      <p-progressSpinner styleClass="w-3rem h-3rem" strokeWidth="5" fill="var(--surface-ground)" animationDuration=".75s" />
      <div class="font-medium text-2xl text-900">Bramka płatności otwarta</div>
    </div>
    <p class="line-height-3 p-0 mx-0 my-1">
      Przejdź do ostatnio otwartej zakładki i wykonaj płatność.
    </p>
  }
  <ng-template pTemplate="footer">
    <div class=" border-top-1 surface-border pt-3 flex">
      @if (!this.formSentinel.waitingForPayment) {
        <button pButton pRipple icon="fa-solid fa-reply-all" (click)="nextStep = false" label="Wróć"
                class="p-button-outlined w-6 mr-2"></button>
        <a class="w-6 ml-2 no-underline" pButton pRipple icon="fa-solid fa-up-right-from-square" (click)="toPayment($event);" label="Opłać">&nbsp;</a>
      }
    </div>
  </ng-template>
</p-dialog>
