import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { catchError, Observable, of, tap } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loggedIn: boolean = false;
  constructor(private http: HttpClient, private router: Router) {}

  // Przykładowa metoda do sprawdzania, czy użytkownik jest zalogowany
  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  localLogin(login: string, password: string): Observable<Object> {
    return this.http.post('/api/auth', { login, password }, { withCredentials: true }).pipe(
      tap((response: any) => {
        if (response.response.statusCode == 200) {
          this.loggedIn = true;
        }
      }),
      catchError(this.handleError)
    );
  }

  // Przykładowa metoda do wylogowywania użytkownika
  logout(): Observable<Object> {
    return this.http.delete('/api/auth', { withCredentials: true }).pipe(
      tap((response: any) => {
        if (response.response.statusCode == 200) {
          this.loggedIn = false;
        }
      }),
      catchError(this.handleError)
    );
  }

  resetPassword(data: any) {
    return this.http.patch("/api/auth", data).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    return of({ ...error });
  }
}
