<div class="flex flex-column flex-auto w-screen h-screen">
  <div style="background: url('https://blocks.primeng.org/assets/images/blocks/signin/signin-2.jpg') no-repeat; background-size: cover"
       class="px-3 py-8 sm:px-8 w-full h-full justify-content-center align-content-center">
    <div class="flex flex-wrap-reverse md:flex-wrap justify-content-center align-content-center">
      <div class="w-full w-auto sm:w-7 lg:w-4 p-4 lg:p-7 surface-card">
        <div class="text-900 text-2xl font-medium mb-6">Resetowanie hasła</div>
        <form>
          <div class="mb-4">
            <p-floatLabel>
              <p-password placeholder="Podaj nowe hasło" formControlName="passwordNew" inputId="password" class="inline-block w-full" inputStyleClass="w-full"
                          toggleMask="true" feedback="false" styleClass="w-full">
              </p-password>
              <label for="password" class="block text-900">Nowe hasło</label>
            </p-floatLabel>
            <small class="inline-block w-full p-error text-center" *ngIf="false">
              Podaj hasło.
            </small>
          </div>
          <div class="mb-4">
            <p-floatLabel>
              <p-password placeholder="Powtórz hasło" formControlName="passwordRepeat" inputId="passwordRepeat" class="inline-block w-full"
                          inputStyleClass="w-full"
                          toggleMask="true" feedback="false" styleClass="w-full">
              </p-password>
              <label for="passwordRepeat" class="block text-900">Powtórz hasło</label>
            </p-floatLabel>
            <small class="inline-block w-full p-error text-center" *ngIf="false">
              Podaj hasło.
            </small>
          </div>
          <div class="grid">
            <div class="col-4">
              <div class="flex align-items-center">
              </div>
            </div>
            <div class="col-4">
              <small class="inline-block w-full p-error text-center" *ngIf="false">
                Błędne dane logowania.
              </small>
            </div>
            <div class="col-4 text-right">
            </div>
          </div>
          <button pButton pRipple label="Zmień hasło" type="submit" icon="fa-solid fa-retweet" class="w-full"></button>
        </form>
      </div>
    </div>
  </div>
</div>
