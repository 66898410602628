import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreatorHomeComponent } from './creator/creator-home/creator-home.component';
import { PanelHomeComponent } from './panel/panel-home/panel-home.component';
import { BookingComponent } from './creator/booking/booking.component';
import { OrderFormComponent } from './creator/order-form/order-form.component';
import { OrderSummaryComponent } from './creator/order-summary/order-summary.component';
import { CreatorLayoutComponent } from "./creator/creator-layout/creator-layout.component";
import { PanelLayoutComponent } from "./panel/panel-layout/panel-layout.component";
import { ReservationsComponent } from "./panel/reservations/reservations.component";
import { ReservationDetailsComponent } from "./panel/reservations/reservation-details/reservation-details.component";
import { LocationsComponent } from "./panel/locations/locations.component";
import { LocationDetailsComponent } from "./panel/locations/location-details/location-details.component";
import { StoragesComponent } from "./panel/storages/storages.component";
import { StorageDetailsComponent } from "./panel/storages/storage-details/storage-details.component";
import { StorageContainersComponent } from "./panel/storageContainers/storage-containers.component";
import { StorageContainerDetailsComponent } from "./panel/storageContainers/storage-container-details/storage-container-details.component";
import { LoginComponent } from "./shared/login/login.component";
import { authGuard } from "./auth.guard";
import { ResetPasswordComponent } from './shared/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: "creator",
    component: CreatorLayoutComponent ,
    children: [
      {
        path: "",
        component: CreatorHomeComponent
      },
      {
        path: "booking",
        component: BookingComponent
      },
      {
        path: "orderForm",
        component: OrderFormComponent
      },
      {
        path: "orderSummary/:id",
        component: OrderSummaryComponent,
        pathMatch: "prefix",
      },
    ]
  },
  {
    path: "panel",
    component: PanelLayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: "",
        component: PanelHomeComponent
      },
      {
        path: "reservations",
        component: ReservationsComponent,
      },
      {
        path: "reservations/details",
        component: ReservationDetailsComponent,
      },
      {
        path: "locations",
        component: LocationsComponent,
      },
      {
        path: "locations/details",
        component: LocationDetailsComponent,
      },
      {
        path: "storageContainers",
        component: StorageContainersComponent,
      },
      {
        path: "storageContainers/details",
        component: StorageContainerDetailsComponent,
      },
      {
        path: "storages",
        component: StoragesComponent,
      },
      {
        path: "storages/details",
        component: StorageDetailsComponent,
      },
    ]
  },
  { path: "login", component: LoginComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "", redirectTo: '/creator', pathMatch: "full"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
