<div class="px-2 pt-4 pb-2 md:px-2 lg:px-4">
  <div class="grid">
    <div class="col-12">
      <p-tabView styleClass="tabview-custom" [scrollable]="true" [autoHideButtons]="true">
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-regular fa-file-lines mr-2 text-xl"></i>
            <span>Dane</span>
          </ng-template>
          <div class="grid">
            <div class="col-12">
              <div class="px-1 py-3 md:px-2 lg:px-4">
                <header class="grid">
                  <div class="col-12">
                    <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
                      <div class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border">
                        <div class="flex align-items-center">
                          <i class="pi pi-inbox text-2xl mr-3 text-500"></i>
                          <span class="text-3xl font-medium text-900">Rezerwacja #{{ reservationId }}</span>
                        </div>
                        <div class="mt-3 md:mt-0">
                          <!--<button pButton pRipple label="Compose" icon="pi pi-plus"></button>-->
                          <p-button class="p-button-outlined mr-3" pRipple (click)="toolboxMenu.toggle($event)" label="Narzędzia"
                                    icon="fa-solid fa-screwdriver-wrench"/>
                          <p-menu #toolboxMenu [model]="toolbox" [popup]="true" appendTo="body"
                                  styleClass="w-20rem px-1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </header>
                <div class="surface-card p-4 shadow-2 border-round">
                  <div>
                    <form class="grid formgrid p-fluid" [formGroup]="reservationDataForm" (ngSubmit)="onSubmitDataTab();">
                      <!--<div class="col-12 md:col-6 field mb-4">
                        <label for="nickname2" class="font-medium text-900">Tekst:</label>
                        <input id="nickname2" type="text" pInputText value="" [disabled]="true">
                      </div>-->
                      <div class="col-12 md:col-6 field p-fluid mb-4">
                        <span class="p-float-label">
                          <p-dropdown [options]="reservationsStatuses" formControlName="reservationStatus" optionLabel="label" optionValue="value"
                                      [showClear]="true" inputId="reservationStatusInput" styleClass="w-full text-center"
                                      [ngClass]="{
                                        'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.reservationStatus.errors,
                                        'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.reservationStatus.errors
                                      }"
                          >
                          </p-dropdown>
                          <label for="reservationStatus">Status rezerwacji <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                        </span>
                        <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.companyType.errors?.required">
                          Wybierz rodzaj przedsiębiorstwa.
                        </small>
                      </div>
                      <p-divider class="col-12 field" align="center">
                        <p class="block font-medium text-900 text-xl mx-3">Dane najemcy</p>
                      </p-divider>
                      <div class="field p-fluid mb-4 col-12 md:col-6">
                        <p-floatLabel>
                          <input id="email" type="email" formControlName="email" pInputText class="p-inputtext w-full"
                                 [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.email.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.email.errors }">
                          <label for="email"> E-mail <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                        </p-floatLabel>
                        <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.email.errors?.required">Podaj adres
                          e-mail.</small>
                        <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.email.errors?.email">Podany adres e-mail
                          jest
                          niepoprawny.</small>
                      </div>
                      <div class="field p-fluid mb-4 col-12 md:col-6">
                        <!--<phone-ctrl [phoneNumberControl]="phoneNumber" [countryCodeControl]="countryCode"></phone-ctrl>-->
                        <p-floatLabel>
                          <input id="phone" type="text" formControlName="phone" pInputText class="p-inputtext w-full"
                                 [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.phone.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.phone.errors }"
                          >
                          <label for="phone"> Telefon <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                        </p-floatLabel>
                        <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.phone.errors?.required">Podaj numer
                          telefonu.</small>
                        <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.phone.errors?.pattern">Podany numer
                          telefonu jest
                          niepoprawny.</small>
                      </div>
                      <!--<div class="field mb-4 col-12 md:col-6">
                        <label for="city2" class="font-medium text-900">City</label>
                        <input id="city2" type="text" pInputText [disabled]="true" />
                      </div>
                      <div class="field mb-4 col-12 md:col-6">
                        <label for="state2" class="font-medium text-900">State</label>
                        <input id="state2" type="text" pInputText [disabled]="true" />
                      </div>
                      <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                      <div class="field mb-4 col-12">
                        <label for="website2" class="font-medium text-900">Website</label>
                        <div class="p-inputgroup">
                          <span class="p-inputgroup-addon">www</span>
                          <input id="website2" type="text" pInputText [disabled]="true" />
                        </div>
                      </div>-->
                      <!--<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                      <div class="field mb-4 col-12">
                        <label for="privacy2" class="font-medium text-900">Privacy</label>
                        <div class="flex align-items-center">
                          <p-inputSwitch id="privacy2" inputId="privacy2" [disabled]="true"></p-inputSwitch>
                          <span class="ml-2 text-900">Share my data with contacts</span>
                        </div>
                      </div>-->
                      <div class="col-12 field p-fluid mb-4 mt-3">
                        <div class="grid">
                          <div class="col-6 align-items-center">
                            <p-radioButton name="subjectType" formControlName="subjectType" value="person" inputId="person"
                                           [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.subjectType.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.subjectType.errors }"
                            ></p-radioButton>
                            <label for="person" class="ml-2">Osoba fizyczna</label>
                          </div>
                          <div class="col-6 align-items-center">
                            <p-radioButton name="subjectType" formControlName="subjectType" value="company" inputId="company"
                                           [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.subjectType.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.subjectType.errors }"
                            ></p-radioButton>
                            <label for="company" class="ml-2">Firma</label>
                          </div>
                          <div class="col-12 align-items-center"
                               *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.subjectType.errors?.required">
                            <small class="p-error">Wybierz podmiot prawny.</small>
                          </div>
                        </div>
                      </div>
                      @if (reservationDataForm.controls.subjectType.getRawValue() != '') {
                        @if (reservationDataForm.controls.subjectType.getRawValue() == 'person') {
                          <div class="col-12 field p-fluid mb-4">
                            <p-floatLabel>
                              <input id="surnames" formControlName="surNames" type="text" pInputText class="p-inputtext w-full"
                                     [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.surNames.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.surNames.errors }"
                              >
                              <label for="surnames"> Imię / Imiona <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.surNames.errors?.required">Podaj imię
                              / imiona
                              .</small>
                          </div>
                          <div class="col-12 field p-fluid mb-4">
                            <p-floatLabel>
                              <input id="lastname" formControlName="lastName" type="text" pInputText class="p-inputtext w-full"
                                     aria-describedby="lastName-message"
                                     [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.lastName.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.lastName.errors }"
                              >
                              <label for="lastName"> Nazwisko <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small id="lastName-message" class="p-error"
                                   *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.lastName.errors?.required">Podaj nazwisko.</small>
                          </div>
                          <div class="col-12 field p-fluid mb-4">
                            <div class="flex justify-content-center flex-wrap gap-2 sm:gap-5">
                              <div class="flex align-items-center">
                                <span>Posiadasz PESEL: </span>
                              </div>
                              <div class="flex align-items-center">
                                <p-radioButton name="hasPESEL" formControlName="hasPESEL" [value]="1" inputId="plCitizen"
                                               [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.hasPESEL.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.hasPESEL.errors }"
                                ></p-radioButton>
                                <label for="plCitizen" class="ml-2">tak</label>
                              </div>
                              <div class="flex align-items-center">
                                <p-radioButton name="hasPESEL" formControlName="hasPESEL" [value]="0" inputId="nonplCitizen"
                                               [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.hasPESEL.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.hasPESEL.errors }"
                                ></p-radioButton>
                                <label for="hasPESEL" class="ml-2">nie</label>
                              </div>
                            </div>
                            <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.hasPESEL.errors?.required">Podaj, czy
                              posiadasz
                              numer PESEL.</small>
                          </div>
                          @if (this.reservationDataForm.controls.hasPESEL.value != null) {
                            @if (reservationDataForm.get("hasPESEL")?.value == 1) {
                              <div class="col-12 field p-fluid mb-4">
                                <p-floatLabel>
                                  <input id="pesel" formControlName="PESEL" pInputText type="text" class="p-inputtext w-full"
                                         [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.PESEL.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.PESEL.errors }"
                                  >
                                  <label for="pesel"> PESEL <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                                </p-floatLabel>
                                <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.PESEL.errors?.required">Podaj
                                  numer
                                  PESEL.</small>
                                <small class="p-error"
                                       *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.PESEL.errors?.required === undefined && this.reservationDataForm.controls.PESEL.errors?.invalidPesel">Numer
                                  PESEL jest nieprawidłowy.</small>
                              </div>
                            } @else {
                              <div class="col-12 field p-fluid mb-4">
                                <p-floatLabel>
                                  <input id="passportNumber" formControlName="passportNumber" pInputText type="text" class="p-inputtext w-full"
                                         [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.passportNumber.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.passportNumber.errors }"
                                  >
                                  <label for="passportNumber"> Numer paszportu <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                                </p-floatLabel>
                                <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.passportNumber.errors?.required">Podaj
                                  numer paszportu.</small>
                              </div>
                            }
                          } @else {
                            <!--<div class="col-12 field mb-4">
                              <div class="border-2 border-dashed surface-border flex-auto overflow-y-auto p-3 mx-2 my-3">
                                <p>
                                  <i class="fa-solid fa-arrow-up"></i> Czy posiadasz numer PESEL?
                                </p>
                              </div>
                            </div>-->
                          }
                        } @else if (this.reservationDataForm.controls.subjectType.value == 'company') {
                          <!--<div class="col-12 field p-fluid mb-4">
                            <span class="p-float-label">
                              <p-dropdown [options]="companyTypes" formControlName="companyType" optionLabel="name" [showClear]="true" inputId="companyType"
                                          styleClass="w-full text-center" [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.get('companyType')?.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.get('companyType')?.errors }">
                              </p-dropdown>
                              <label for="companyType">Rodzaj przedsiębiorstwa <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </span>
                            <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.get('companyType')?.errors?.required" >Wybierz rodzaj przedsiębiorstwa.</small>
                          </div>-->
                          <div class="col-12 field p-fluid mb-4">
                            <p-floatLabel>
                              <p-inputMask id="nip" type="text" formControlName="nipNumber" styleClass="w-full" characterPattern="[A-Z]"
                                           slotChar="XX ### ### ## ##" mask="aa 999 999 99 99" [autoClear]="true"
                                           [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.nipNumber.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.nipNumber.errors }">
                              </p-inputMask>
                              <label for="nip">NIP <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.nipNumber.errors?.required">
                              Podaj numer NIP z kodem kraju.
                            </small>
                          </div>
                          <!--<div class="col-12 field p-fluid mb-4">
                            <p-floatLabel>
                              <input id="regon" formControlName="regonNumber" pInputText type="text" class="p-inputtext w-full"
                                     [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.get('regonNumber')?.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.get('regonNumber')?.errors }"
                              >
                              <label for="regon">REGON <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.get('regonNumber')?.errors?.required">Podaj numer REGON.</small>
                          </div>
                          @if (reservationDataForm.controls.companyType.value && this.reservationDataForm.controls.companyType.value.requireKRS) {
                            <div class="col-12 field p-fluid mb-4">
                              <p-floatLabel>
                                <input id="krs" formControlName="krsNumber" pInputText type="text" class="p-inputtext w-full"
                                       [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.get('krsNumber')?.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.get('krsNumber')?.errors }"
                                >
                                <label for="krs">KRS <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                              </p-floatLabel>
                              <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.get('krsNumber')?.errors?.required">Podaj numer KRS.</small>
                            </div>
                          }-->
                          <div class="col-12 field mb-4">
                            <p-floatLabel>
                              <input id="cn" formControlName="companyName" pInputText type="text" class="p-inputtext w-full"
                                     [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.companyName.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.companyName.errors }">
                              <label for="cn">Nazwa firmy <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.companyName.errors?.required">Podaj
                              nazwę
                              firmy.</small>
                          </div>
                        }
                        @if (!["", null].includes(this.reservationDataForm.controls.subjectType.getRawValue()) || !["", null].includes(this.reservationDataForm.controls.hasPESEL.value)) {
                          <div class="col-12 field mb-4">
                            <p-floatLabel>
                              <input id="address" formControlName="addressName" pInputText type="text" class="p-inputtext w-full"
                                     [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.addressName.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.addressName.errors }">
                              <label for="address">Adres (ulica / aleja) <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.addressName.errors?.required">Podaj
                              adres.</small>
                          </div>
                          <div class="col-12 lg:col-6 field mb-4">
                            <p-floatLabel>
                              <input id="addressNumber" formControlName="addressNumber" pInputText type="text" class="p-inputtext w-full"
                                     [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.addressNumber.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.addressNumber.errors }"
                              >
                              <label for="addressNumber">Numer ulicy <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.addressNumber.errors?.required">Podaj
                              numer
                              ulicy.</small>
                          </div>
                          <div class="col-12 lg:col-6 field mb-4">
                            <p-floatLabel>
                              <input id="address-apartment" formControlName="addressApartment" pInputText type="text" class="p-inputtext w-full">
                              <label for="address-apartment">Numer lokalu</label>
                            </p-floatLabel>
                          </div>
                          <div class="col-12 field mb-4">
                            <p-floatLabel>
                              <input id="pc" formControlName="postalCode" pInputText type="text" class="p-inputtext w-full"
                                     [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.postalCode.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.postalCode.errors }"
                              >
                              <label for="pc">Kod pocztowy <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.postalCode.errors?.required">Podaj
                              kod
                              pocztowy.</small>
                          </div>
                          <div class="col-12 field mb-4">
                            <p-floatLabel>
                              <input id="town" formControlName="town" pInputText type="text" class="p-inputtext w-full"
                                     [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.town.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.town.errors }"
                              >
                              <label for="town">Miejscowość <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.controls.town.errors?.required">Podaj
                              miejscowość.</small>
                          </div>
                        }
                      } @else {
                        <div class="col-12 field mb-4">
                          <div class="border-2 border-dashed surface-border flex-auto overflow-y-auto p-3 mx-2 my-3">
                            <p>
                              <i class="fa-solid fa-arrow-up"></i> Wybierz podmiot prawny.
                            </p>
                          </div>
                        </div>
                      }
                      <p-divider class="col-12 field" align="center">
                        <p class="block font-medium text-900 text-xl mx-3">Zgody i umowa przedwstępna</p>
                      </p-divider>
                      <div class="col-12 mb-3 flex flex-row align-items-center gap-1 text-left">
                        <p-checkbox [value]="true" [binary]="true" class="text-900" inputId="emailNewsletter" formControlName="newsletterCheck"></p-checkbox>
                        <label for="newsletterCheck">
                          Wyrażam zgodę na otrzymywanie wiadomości e-mail z nowościami, promocjami i ofertami.
                        </label>
                      </div>
                      <div class="col-12 mb-3 flex align-items-center gap-1 text-left">
                        <p-checkbox [value]="true" [binary]="true" inputId="atos" formControlName="atos"
                                    [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.atos.errors?.required, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.atos.errors?.required }"
                        ></p-checkbox>
                        <label for="atos" [ngClass]="{'p-error': this.formSentinel.submitted && this.reservationDataForm.controls.atos.errors?.required }">
                          <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> Akceptuję <a href="#" target="_blank">regulamin serwisu</a>
                        </label>
                      </div>
                      <div class="col-12 mb-3 flex flex-row align-items-center gap-1 text-left">
                        <p-checkbox [value]="true" [binary]="true" inputId="paf" formControlName="paf"
                                    [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.paf.errors?.required, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.paf.errors?.required }"
                        ></p-checkbox>
                        <label for="paf" [ngClass]="{'p-error': this.formSentinel.submitted && this.reservationDataForm.controls.paf.errors?.required}">
                          <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> Oświadczam, że zapoznałem/-am się z umową przedwstępną.
                        </label>
                      </div>
                      <div class="col-12 mb-3 flex flex-row align-items-center gap-1 text-left">
                        <p-checkbox [value]="true" [binary]="true" inputId="pas" formControlName="pas"
                                    [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.pas.errors?.required, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.pas.errors?.required }"
                        ></p-checkbox>
                        <label for="pas" [ngClass]="{'p-error': this.formSentinel.submitted && this.reservationDataForm.controls.pas.errors?.required}">
                          <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> Oświadczam, że załączam umowę przedwstępną wraz z podpisem
                          osoby upoważnionej / podmiotu upoważnionego.
                        </label>
                      </div>
                      @if (reservationDataForm.controls.subjectType.getRawValue() == 'person') {
                        <div class="col-12 mb-3 flex align-items-center gap-1 text-left">
                          <p-checkbox [value]="true" [binary]="true" inputId="krdCheck" formControlName="krdCheck"
                                      [ngClass]="{
                                'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.controls.krdCheck.errors?.required,
                                'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.controls.krdCheck.errors?.required
                              }"
                          ></p-checkbox>
                          <label for="krdCheck"
                                 [ngClass]="{'p-error': this.formSentinel.submitted && this.reservationDataForm.controls.krdCheck.errors?.required}">
                            <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> Wyrażam zgodę, na pobranie mojej historii w Krajowym
                            Rejestrze Długów (KRD).
                          </label>
                        </div>
                      }
                      <div class="col-12 field mb-3 flex flex-column justify-content-center">
                        <small class="p-error" *ngIf="this.formSentinel.submitted && !this.formSentinel.downloadContract">Pobierz umowę przedwstępną.</small>
                        <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.invalid && !this.formSentinel.downloadContract">Formularz
                          powyżej zawiera błędy.</small>
                        <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.invalid && this.formSentinel.downloadContract">Zaakceptuj
                          wymagane zgody poniżej.</small>
                      </div>
                      <div class="col-12 field mb-3 px-5">
                        <p-table [value]="reservationData.reservationForm.contractFiles" [tableStyle]="{ 'min-width': '30rem' }">
                          <ng-template pTemplate="header">
                            <tr>
                              <th style="width:60%">Załącznik</th>
                              <th style="width:25%">Rozmiar</th>
                              <th style="width:15%"></th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-file>
                            <tr>
                              <td>{{ file.originalName + file.extension }}</td>
                              <td>{{ file.size | fileSize }}</td>
                              <td>
                                <a class="p-button font-bold no-underline justify-content-center" [href]="file.url" target="_blank" rel="noopener noreferrer">
                                  <i class="fa-solid fa-download mr-2"></i> Pobierz
                                </a>
                              </td>
                            </tr>
                          </ng-template>
                        </p-table>
                        <!--<p-fileUpload [files]="uploadedFiles" name="signedContractField" [multiple]="true" [maxFileSize]="10000000" [customUpload]="true"
                                      accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text"
                                      (uploadHandler)="console.log($event);" (onSelect)="console.log($event);" (onRemove)="console.log($event);"
                                      [showUploadButton]="false" [disabled]="true">
                          <ng-template pTemplate="toolbar">
                            <div class="pt-4 pb-0">Wgrano plików: {{ this.uploadedFiles.length }}</div>
                          </ng-template>
                          <ng-template pTemplate="content">
                              <span [ngClass]="{'p-error': this.formSentinel.submitted && !this.formSentinel.signedContract}"
                                    *ngIf="this.uploadedFiles.length == 0">
                                <span *ngIf="this.formSentinel.submitted && !this.formSentinel.signedContract"
                                      class="fa-solid fa-asterisk vertical-align-super text-2xs">
                                </span>
                                Wgraj plik/pliki zawierającą podpisaną umowę.
                              </span>
                          </ng-template>
                        </p-fileUpload>-->
                      </div>
                      <p-divider class="w-full"></p-divider>
                      <div *ngIf="this.messages.length > 0" class="col-12">
                        <p-messages [(value)]="messages" [enableService]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'" />
                      </div>
                      <div class="col-12">
                        <button pButton pRipple icon="fa-solid fa-floppy-disk" iconPos="left" label="Zapisz zmiany" class="w-auto mt-3"></button>
                      </div>
                    </form>
                  </div>
                  <div class="grid">
                    <p-divider class="col-12 field" align="center">
                      <p class="block font-medium text-900 text-xl mx-3">Szczegóły rezerwacji</p>
                    </p-divider>
                    <div class="col-12 field mb-5">
                      <p-table [value]="reservationData.reservationDetails" [tableStyle]="{ 'min-width': '50rem' }">
                        <ng-template pTemplate="header">
                          <tr>
                            <th style="width: 50%;">Wybrany rodzaj kontenera</th>
                            <th style="width: 15%;">Ilość</th>
                            <th style="width: 35%;">Cena jednostkowa</th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-detail>
                          <tr>
                            <td>{{ detail.groupPrices.group.name }}</td>
                            <td>{{ detail.quantityReserved }}</td>
                            <td>{{ detail.nettoUnitPrice | currency:"PLN" }} netto</td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </div>
                    <div class="col-12 md:col-6 field mb-4">
                      <p-floatLabel>
                        <p-inputNumber class="text-center mx-1 my-1" inputId="rentalPeriod" [(ngModel)]="reservationData.rentalPeriod" inputStyleClass="w-full"
                                       [showButtons]="false" [step]="1" [min]="1" [max]="24" [disabled]="true"
                                       [suffix]="' ' + this.creatorService.getMonthString(reservationData.rentalPeriod)"
                        >
                        </p-inputNumber>
                        <label for="rentalPeriod">
                          Okres najmu:
                        </label>
                      </p-floatLabel>
                    </div>
                    <div class="col-12 md:col-6 field mb-4">
                      <p-floatLabel>
                        <p-inputNumber inputStyleClass="w-full" [(ngModel)]="reservationData.totalNettoPrice"
                                       mode="currency" inputId="totalNettoPrice" currency="PLN" locale="pl-PL" [disabled]="true"
                        >
                        </p-inputNumber>
                        <label for="totalNettoPrice">
                          Wartość rezerwacji (netto):
                        </label>
                      </p-floatLabel>
                    </div>
                    <div class="col-12 md:col-6 field mb-4">
                      <p-floatLabel>
                        <p-inputNumber inputStyleClass="w-full" [(ngModel)]="reservationData.totalBruttoPrice"
                                       mode="currency" inputId="totalBruttoPrice" currency="PLN" locale="pl-PL" [disabled]="true"
                        >
                        </p-inputNumber>
                        <label for="totalBruttoPrice">
                          Wartość rezerwacji (brutto):
                        </label>
                      </p-floatLabel>
                    </div>
                    <div class="col-12 md:col-6 field mb-4">
                      <p-floatLabel>
                        <p-inputNumber inputStyleClass="w-full" [(ngModel)]="reservationData.totalCommission"
                                       mode="currency" inputId="totalCommission" currency="PLN" locale="pl-PL" [disabled]="true"
                        >
                        </p-inputNumber>
                        <label for="totalCommission">
                          Naliczona zniżka (brutto):
                        </label>
                      </p-floatLabel>
                    </div>
                  </div>
                  <p-divider class="field" align="center">
                    <p class="block font-medium text-900 text-xl mx-3">Zarezerwowane kontenery</p>
                  </p-divider>
                  <div class="col-12 field mb-4">
                    <p-table [value]="reservationData.storageContainers" dataKey="id" editMode="row" [tableStyle]="{'min-width': '15rem'}">
                      <ng-template pTemplate="header">
                        <tr>
                          <th style="width:60%">Kontener</th>
                          <th style="width:40%"></th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-container let-editing="editing" let-ri="rowIndex">
                        <tr [pEditableRow]="container">
                          <td>
                            <p-cellEditor>
                              <ng-template pTemplate="input">
                                <p-dropdown [options]="this.availableContainers" [(ngModel)]="this.selectedContainer[ri]"
                                            [ngModelOptions]="{standalone: true, updateOn: 'change'}"
                                            [group]="true" optionLabel="storageNumber" appendTo="body"
                                            [style]="{'width':'100%'}" [filter]="true" [showClear]="true"
                                            filterBy="storageNumber" [resetFilterOnHide]="true"
                                >
                                  <!-- ToDo: dodać filtrowanie opcji -->
                                  <!--<ng-template pTemplate="filter" let-filterItems>
                                    <div class="flex gap-1">
                                      <div class="p-inputgroup" (click)="$event.stopPropagation()">
                                        <span class="p-inputgroup-addon"><i class="fa-solid fa-magnifying-glass"></i></span>
                                        <input type="text" pInputText placeholder="Filtruj..."
                                               [(ngModel)]="filterValue" (keyup)="customFilterFunction($event, filterItems);"
                                        />
                                      </div>
                                      <button pButton icon="fa-solid fa-xmark" (click)="resetFunction(filterItems);"></button>
                                    </div>
                                  </ng-template>-->
                                  <ng-template pTemplate="group" let-group>
                                    <div class="flex align-items-center">
                                      <span>{{ group.label }}</span>
                                    </div>
                                  </ng-template>
                                  <ng-template pTemplate="selectedItem">
                                    <div class="flex align-items-center">
                                      <span>{{ this.selectedContainer[ri].storageNumber }} ({{ this.selectedContainer[ri].group.name }})</span>
                                    </div>
                                  </ng-template>
                                  <ng-template pTemplate="item" let-item>
                                    <div class="flex align-items-center">
                                      <span>{{ item.storageNumber }} ({{item.group.name}})</span>
                                    </div>
                                  </ng-template>
                                </p-dropdown>
                              </ng-template>
                              <ng-template pTemplate="output">
                                {{ container.storageNumber }} ({{ container.group.name }})
                              </ng-template>
                            </p-cellEditor>
                          </td>
                          <td>
                            <div class="flex align-items-center justify-content-center gap-2">
                              <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="fa-solid fa-pencil" label="Edytuj"
                                      (click)="onRowEditInit(container)" class="p-button-rounded p-button-text">
                              </button>
                              <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="fa-solid fa-check"
                                      (click)="onRowEditSave(this.selectedContainer[ri], ri)" label="Zatwierdź"
                                      class="p-button-rounded p-button-text p-button-success mr-2">
                              </button>
                              <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="fa-solid fa-xmark" label="Anuluj"
                                      (click)="onRowEditCancel(container)" class="p-button-rounded p-button-text p-button-danger">
                              </button>
                            </div>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                  <div *ngIf="this.containersTableMessage.length > 0" class="col-12">
                    <p-messages [(value)]="containersTableMessage" [enableService]="false" [showTransitionOptions]="'500ms'"
                                [hideTransitionOptions]="'500ms'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-clock-rotate-left mr-2 text-xl"></i>
            <span>Historia</span>
          </ng-template>
          <div class="card">
            <p-timeline [value]="events" align="alternate" styleClass="customized-timeline">
              <ng-template pTemplate="marker" let-event>
                <span class="shadow-2 flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1"
                      [style.backgroundColor]="event.color">
                  <i [ngClass]="event.icon"></i>
                </span>
              </ng-template>
              <ng-template pTemplate="content" let-event>
                <p-card [header]="event.status" [subheader]="event.date">
                  <img *ngIf="event.image" [src]="'https://primefaces.org/cdn/primeng/images/demo/product/' + event.image" [alt]="event.name" width="200"
                       class="shadow-2" />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat
                    libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate
                    neque quas!
                  </p>
                  <button pButton label="Read more" class="p-button-text"></button>
                </p-card>
              </ng-template>
            </p-timeline>
          </div>
        </p-tabPanel>
        <!-- ToDo: przenieść zakładkę "Zasoby" do karty klienta -->
        <!--<p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-paperclip mr-2 text-xl"></i>
            <span>Zasoby</span>
          </ng-template>
          <div class="grid">
            <div class="col-12">
              <div class="px-1 py-3 md:px-2 lg:px-4">
                <div class="surface-card p-4 shadow-2 border-round">
                  <div class="grid formgrid p-fluid">
                    <div class="field mb-4 col-12">
                      <label for="notes" class="font-medium text-900">Notatki:</label>
                      <p-editor id="notes" [style]="{ height: '320px' }"></p-editor>
                    </div>
                    <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                    <div class="field mb-4 col-12">
                      <label for="files" class="font-medium text-900">Pliki:</label>
                      <div class="card flex justify-content-center z-4">
                        <p-fileUpload id="files" class="w-full" name="demo[]" url="https://www.primefaces.org/cdn/api/upload.php" (onUpload)="onUpload($event)"
                                      [multiple]="true" accept="image/*,application/pdf" [maxFileSize]="1000000" [mode]="'advanced'" uploadLabel="Wgraj"
                                      chooseLabel="Dodaj">
                          <ng-template pTemplate="content">
                            <ul *ngIf="uploadedFiles.length" class="list-none p-0 m-0">
                              <li *ngFor="let file of uploadedFiles" class="pb-2">
                                <div class="px-4 py-1">
                                  <p-image *ngIf="file.mimeType.includes('image')" [src]="file.objectURL.changingThisBreaksApplicationSecurity"
                                           [preview]="true" [previewImageSrc]="file.objectURL.changingThisBreaksApplicationSecurity" height="25rem">
                                  </p-image>
                                  {{ file.name }} - {{ file.size }} bytes
                                </div>
                              </li>
                            </ul>
                          </ng-template>
                        </p-fileUpload>
                      </div>
                    </div>
                    <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                    <div class="col-12">
                      <button pButton pRipple label="Save Changes" class="w-auto mt-3"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>-->
      </p-tabView>
    </div>
  </div>
</div>
