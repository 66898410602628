<div class="px-2 pt-4 pb-2 md:px-2 lg:px-4">
  <div class="grid">
    <div class="col-12">
      <p-tabView styleClass="tabview-custom" [scrollable]="true" [autoHideButtons]="true">
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-regular fa-file-lines mr-2 text-xl"></i>
            <span>Szczegóły</span>
          </ng-template>
          <div class="surface-ground px-4 py-5 md:px-5 lg:px-6">
            <div class="surface-card p-4 shadow-2 border-round">
              <div class="grid formgrid p-fluid">
                <p-divider class="col-12" align="center">
                  <p class="font-medium text-900">Dane</p>
                </p-divider>
                <div class="field mb-4 col-12 md:col-6">
                  <label for="name" class="font-normal text-900">Nazwa</label>
                  <input id="name" type="text" [(ngModel)]="location.name" pInputText>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <label for="iban_input">IBAN</label>
                  <p-inputMask mask="aa 99 9999 9999 9999 9999 9999 9999" slotChar="PL ## #### #### #### #### #### ####" [(ngModel)]="location.bankAccountNumber" id="iban_input"></p-inputMask>
                </div>
                <p-divider class="col-12" align="center">
                  <p class="font-medium text-900">Karta</p>
                </p-divider>
                <div class="field mb-4 sm:mb-0 col-12 md:col-4">
                  <label for="avatar2" class="font-normal text-900">Miniatura</label>
                  <div class="flex flex-column align-items-center">
                    <img ngSrc="{{location.thumbnail.url}}" class="py-3 mb-2" alt="Avatar" width="200" height="200" />
                    <p-fileUpload id="avatar2" mode="basic" name="avatar" url="./upload.php" accept="image/*" [maxFileSize]="1000000" styleClass="p-button-outlined p-button-plain" chooseLabel="Upload Image" chooseIcon="pi pi-upload"></p-fileUpload>
                  </div>
                  <div class="grid mt-6">
                    <div class="field mb-4 col-8">
                      <label for="orderVisibility2" class="font-normal text-900">Kolejność wyświetlania</label>
                      <input id="orderVisibility2" type="text" pInputText [(ngModel)]="location.orderVisibility">
                    </div>
                    <div class="field mb-4 col-4">
                      <div class="flex flex-column align-items-stretch h-full">
                        <label for="available2" class="font-normal text-900 mb-2">Aktywne</label>
                        <p-inputSwitch class="pt-1 sm:pt-3 pb-1" inputId="available2" id="available2" [(ngModel)]="location.available"></p-inputSwitch>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-4 sm:mb-0 col-12 md:col-8">
                  <label for="promotionLabels" class="font-normal text-900">Etykietki promocji:</label>
                  <p-table #dt id="promotionLabels" [value]="promotionLabels" [(selection)]="selectedPromotionLabel" selectionMode="single" dataKey="id" [tableStyle]="{ 'min-width': '25rem' }">
                    <ng-template pTemplate="caption">
                      <div class="flex">
                        <button type="button" pButton pRipple icon="fa-solid fa-plus" (click)="dt.exportCSV()" class="p-button-success mr-2" pTooltip="Dodaj" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="fa-solid fa-pen-to-square" class="p-button-info mr-2" pTooltip="Edytuj" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="fa-solid fa-trash" class="p-button-danger mr-2" pTooltip="Usuń" tooltipPosition="bottom"></button>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width:1%;"></th>
                        <th style="width:3%;">Etykietka</th>
                        <th style="width:1%;">Ikona</th>
                        <th style="width:3%;">Kolor tła</th>
                        <th style="width:1%;">Aktywna</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-promotionLabel let-rowIndex="rowIndex">
                      <tr [pSelectableRow]="promotionLabel" [pSelectableRowIndex]="rowIndex">
                        <td>
                          <p-tableCheckbox [value]="promotionLabel"></p-tableCheckbox>
                        </td>
                        <td>
                          {{ promotionLabel._title }}
                        </td>
                        <td>
                          <span>{{promotionLabel._icon}}</span>
                        </td>
                        <td class="flex align-items-center justify-content-center">
                          <div class='color-box' [ngStyle]="{'background-color': promotionLabel._backgroundColor }"></div>
                        </td>
                        <td class="text-center">
                          <i [ngClass]="{ 'text-green-500 fa-solid fa-square-check': promotionLabel._active, 'text-red-500 fa-solid fa-circle-xmark': !promotionLabel._active }"></i>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
                <p-divider class="col-12" align="center">
                  <p class="font-medium text-900">Położenie</p>
                </p-divider>
                <div class="field mb-4 col-12 md:col-8">
                  <label for="street" class="font-normal text-900">Adres</label>
                  <input id="street" type="text" pInputText [(ngModel)]="location.place.street">
                </div>
                <div class="field mb-4 col-12 md:col-2">
                  <label for="streetNumber" class="font-normal text-900">Numer ulicy</label>
                  <input id="streetNumber" type="text" pInputText [(ngModel)]="location.place.streetNumber">
                </div>
                <div class="field mb-4 col-12 md:col-2">
                  <label for="propertyNumber" class="font-normal text-900">Numer lokalu</label>
                  <input id="propertyNumber" type="text" pInputText [(ngModel)]="location.place.propertyNumber">
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <label for="postalCode" class="font-normal text-900">Kod pocztowy</label>
                  <input id="postalCode" type="text" pInputText [(ngModel)]="location.place.postalCode">
                </div>
                <div class="field mb-4 col-12 md:col-6">
                  <label for="town" class="font-normal text-900">Miejscowość</label>
                  <input id="town" type="text" pInputText [(ngModel)]="location.place.town">
                </div>
                <div class="field mb-4 col-12">
                  <label for="mapUrl" class="font-normal text-900">Link do mapy:</label>
                  <input id="mapUrl" type="text" pInputText [(ngModel)]="location.place.mapUrl">
                </div>
                <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                <div class="col-12 flex flex-row-reverse gap-2 md:gap-3">
                  <button pButton pRipple icon="fa-solid fa-floppy-disk" label="Zapisz" class="w-auto mt-3"></button>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-location-pin mr-2 text-xl"></i>
            <span>Place</span>
          </ng-template>
          <div class="px-2 pt-4 pb-2 md:px-2 lg:px-4">
            <div class="grid">
              <div class="col-12">
                <p-table [value]="yards" dataKey="id" editMode="row" [tableStyle]="{'min-width': '50rem'}" selectionMode="single">
                  <ng-template pTemplate="caption">
                    <div class="flex">
                      <button type="button" pButton pRipple icon="fa-solid fa-plus" (click)="dt.exportCSV()" class="p-button-success mr-2" pTooltip="Dodaj" tooltipPosition="bottom"></button>
                      <button type="button" pButton pRipple icon="fa-solid fa-trash" class="p-button-danger mr-2" pTooltip="Usuń" tooltipPosition="bottom"></button>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width:5%"></th>
                      <th style="width:25%">Nazwa</th>
                      <th style="width:10%">
                        Numer GSM
                        <i class="fa-solid fa-circle-question" pTooltip="Ilość wysyłająca powiadomienie o wyczerpującej się ilości magazynów." tooltipPosition="right"></i>
                      </th>
                      <th style="width:15%"></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-yard let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="yard">
                      <td>
                        <p-tableCheckbox [value]="yard"></p-tableCheckbox>
                      </td>
                      <td>
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <p-dropdown [options]="countries" [(ngModel)]="selectedCountry" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" placeholder="Select a Country" styleClass="w-20rem">
                              <ng-template pTemplate="filter" let-options="options">
                                <div class="flex gap-1">
                                  <div class="p-inputgroup" (click)="$event.stopPropagation()">
                                    <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                    <input type="text" pInputText placeholder="Filter" [(ngModel)]="filterValue" (keyup)="customFilterFunction($event, options)" />
                                  </div>
                                  <button pButton icon="pi pi-times" (click)="resetFunction(options)"></button>
                                </div>
                              </ng-template>
                              <ng-template pTemplate="selectedItem" let-selectedOption>
                                <div class="flex align-items-center gap-2">
                                  <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountry.code.toLowerCase()" style="width: 18px" />
                                  <div>{{ selectedOption.name }}</div>
                                </div>
                              </ng-template>
                              <ng-template let-country pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                  <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" style="width: 18px" />
                                  <div>{{ country.name }}</div>
                                </div>
                              </ng-template>
                            </p-dropdown>
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{yard.name}}
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td>
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <input pInputText type="text" class="w-7rem" [(ngModel)]="yard.price">
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{yard.name}}
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td>
                        <div class="flex align-items-center justify-content-center gap-2">
                          <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(yard)" class="p-button-rounded p-button-text"></button>
                          <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(yard)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                          <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(yard, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-cube mr-2 text-xl"></i>
            <span>Magazyny</span>
          </ng-template>
          <div class="px-2 pt-4 pb-2 md:px-2 lg:px-4">
            <div class="grid">
              <div class="col-12">
                <p-table [value]="products" dataKey="id" editMode="row" [tableStyle]="{'min-width': '50rem'}" selectionMode="single">
                  <ng-template pTemplate="caption">
                    <div class="flex">
                      <button type="button" pButton pRipple icon="fa-solid fa-plus" (click)="dt.exportCSV()" class="p-button-success mr-2" pTooltip="Dodaj" tooltipPosition="bottom"></button>
                      <button type="button" pButton pRipple icon="fa-solid fa-trash" class="p-button-danger mr-2" pTooltip="Usuń" tooltipPosition="bottom"></button>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width:5%"></th>
                      <th style="width:25%">Grupa magazynów</th>
                      <th style="width:10%">
                        Dostępne
                        <i class="fa-solid fa-circle-question" pTooltip="Na podstawie ilości magazynów z statusem &quot;Dostępny&quot; na lokalizacji." tooltipPosition="right"></i>
                      </th>
                      <th style="width:10%">
                        Bufor
                        <i class="fa-solid fa-circle-question" pTooltip="Ilość wysyłająca powiadomienie o wyczerpującej się ilości magazynów." tooltipPosition="right"></i>
                      </th>
                      <th style="width:15%"></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="product">
                      <td>
                        <p-tableCheckbox [value]="product"></p-tableCheckbox>
                      </td>
                      <td>
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <p-dropdown [options]="countries" [(ngModel)]="selectedCountry" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" placeholder="Select a Country" styleClass="w-20rem">
                              <ng-template pTemplate="filter" let-options="options">
                                <div class="flex gap-1">
                                  <div class="p-inputgroup" (click)="$event.stopPropagation()">
                                    <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                    <input type="text" pInputText placeholder="Filter" [(ngModel)]="filterValue" (keyup)="customFilterFunction($event, options)" />
                                  </div>
                                  <button pButton icon="pi pi-times" (click)="resetFunction(options)"></button>
                                </div>
                              </ng-template>
                              <ng-template pTemplate="selectedItem" let-selectedOption>
                                <div class="flex align-items-center gap-2">
                                  <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountry.code.toLowerCase()" style="width: 18px" />
                                  <div>{{ selectedOption.name }}</div>
                                </div>
                              </ng-template>
                              <ng-template let-country pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                  <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" style="width: 18px" />
                                  <div>{{ country.name }}</div>
                                </div>
                              </ng-template>
                            </p-dropdown>
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{product.name}}
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td>
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <input pInputText type="text" class="w-7rem" [(ngModel)]="product.price" [disabled]="true">
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{product.price | number}}
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td>
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <input pInputText type="text" class="w-7rem" [(ngModel)]="product.price">
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{product.price | number}}
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td>
                        <div class="flex align-items-center justify-content-center gap-2">
                          <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(product)" class="p-button-rounded p-button-text"></button>
                          <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(product)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                          <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(product, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
